<template>
    <v-row no-gutters>
        <v-col cols="12">
            <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">Nameservers</h1>
                <!-- <p class="text-caption text-center">{{ name }}</p> -->
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5">
                <v-col cols="12" sm="6">
                    <v-list dense>
                        <v-list-item v-for="(nameserver, idx) in nameserverList" :key="idx">
                            <v-list-item-content>
                                <v-list-item-title>{{ nameserver.fqdn }}</v-list-item-title>
                                <v-list-item-subtitle>{{ nameserver.ipaddr }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';

export default {
    data: () => ({
        nameserverList: [
            { fqdn: 'ns1.libertydns.test', ipaddr: '1.2.3.4' },
            { fqdn: 'ns2.libertydns.test', ipaddr: '5.6.7.8' },
        ],
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
        }),
    },
    watch: {
    },
    methods: {
        init() {
            // this.loadTinydnsConfig();
        },
        async loadServiceSettingsList() {
            try {
                this.$store.commit('loading', { loadServiceSettingsList: true });
                const response = await this.$client.main().system.getSettingsList();
                if (response?.list) {
                    const settingsMap = {};
                    response.list.forEach((item) => {
                        settingsMap[item.name] = item.content;
                    });
                    this.registrationMode = settingsMap.registration_mode ?? 'closed';
                }
            } catch (err) {
                console.error('failed to load service settings', err);
            } finally {
                this.$store.commit('loading', { loadServiceSettingsList: false });
                this.isViewReady = true;
            }
        },
    },
    mounted() {
        this.init();
    },
};
</script>
